<style>
  .max-height {
    max-height: 150px;
  }
</style>
<template>
<div class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-between bg-gradient-to-tr from-teal-500 to-teal-300 dark:from-teal-700 dark:to-teal-500 z-50">
    <div class="relative bg-white dark:bg-gray-900 w-11/12 md:max-w-md mx-auto rounded-lg shadow-lg md:mt-10">
      <div class="absolute top-0 right-0 cursor-pointer" @click="$router.push('/')">
        <svg class="h-8 w-8 p-2 m-1 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
      </div>
      <div class="flex w-full items-center text-gray-800 dark:text-white">
        <svg class="w-full h-12 mt-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#38b2ac" cx="12" cy="12" r="12"/>
            <path fill="#FFF" fill-rule="nonzero" d="M17.57895 17.61404H13.6842l1.61404-7.57895-4.1579 7.57895H8.7544L7.9649 9.77193l-1.6842 7.8421H4.70174L7.07018 6.47369h4.47368l.54386 6.2807 3.36842-6.2807h4.50877z"/>
          </g>
        </svg>
      </div>
      <div class="py-4 px-6">
        <h3 class="font-semibold dark:text-white mb-2">{{ $t('create_account') }}</h3>
        <p class="text-sm text-gray-500 mb-4">{{ $t('create_account_text') }}</p>
        <div v-if="errorMessage" class="p-2 text-sm rounded mb-4 bg-red-200 text-red-700 border border-red-700" v-html="errorMessage">
        </div>
        <form @submit.prevent="register()" class="dark:text-white mt-2">
          <span class="text-sm font-bold">{{ $t('email') }}</span>
          <input v-model="email" class="mt-1 mb-2" type="email" name="email" :placeholder="$t('email')">
          <span class="text-sm font-bold mt-1">{{ $t('password') }} ({{$t('password_min')}})</span>
          <input v-model="password" class="mt-1 mb-2" type="password" name="password" :placeholder="$t('password')">
          <input v-model="password2" class="mt-1 mb-2" type="password" name="password_repeat" :placeholder="$t('password_repeat')">
          <span class="text-sm font-bold mt-1">{{ $t('club') }}</span>
          <div v-if="!club">
          <div class="flex bg-gray-300 dark:bg-gray-700 items-center rounded-lg mb-2">
          <svg class="ml-3 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
          <input v-model="queryClub" class="pl-3 placeholder-gray-600 focus:placeholder-gray-500 dark:placeholder-gray-500 dark:focus:placeholder-gray-600" type="text" placeholder="Suche nach deinem Verein...">
          </div>
          <div v-if="clubsFiltered.length" class="list-label max-height overflow-y-scroll">
            <div v-for="c in clubsFiltered" :key="c.id" @click="club = c" class="option first:rounded-t-lg last:rounded-b-lg">{{c.name}}</div>
          </div>
          </div>
          <template v-else>
            <div class="label flex items-center justify-between">
              <span>{{club.name}}</span>
              <DeleteButton @onDelete="club = null"></DeleteButton>
            </div>
          </template>
          <button class="primary mt-6 mb-4 w-full flex justify-center items-center" :class="[isReady ? '' : 'cursor-not-allowed opacity-50']" :disabled="!isReady || isLoading" type="submit">
          <template v-if="isLoading">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </template>
          <span v-else>{{ $t('signup') }}</span>
          </button>
          <p class="text-sm text-gray-500 mb-4" v-html="$t('create_account_help')"></p>
        </form>
      </div>
    </div>
    <Footer class="text-center"></Footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'
import DeleteButton from '@/components/DeleteButton.vue'
import Footer from '@/components/Footer'

export default {
  components: {
    Footer,
    DeleteButton
  },
  data () {
    return {
      email: '',
      password: '',
      password2: '',
      queryClub: '',
      errorMessage: null,
      club: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      clubs: 'swissunihockey/clubs'
    }),
    clubsFiltered () {
      if (this.queryClub.length === 0) {
        return []
      }
      return this.clubs.filter((c) => c.name.toLowerCase().match(`.*${this.queryClub.toLowerCase()}.*`))
    },
    isReady () {
      return this.email !== '' && this.password !== '' && this.password.length > 5 && this.password === this.password2 && this.club
    },
    isLoading () {
      return this.loading
    }
  },
  methods: {
    ...mapActions(['updateAccessToken', 'updateRefreshToken', 'initialize']),
    register () {
      this.loading = true
      this.errorMessage = null
      ApiClient.post('api/register', { email: this.email, password: this.password, club_id: this.club.id }).then(response => {
        const data = response.data
        this.updateAccessToken(data.access_token)
        this.updateRefreshToken(data.refresh_token)
        this.initialize().then(() => {
          this.$router.push('/account')
          this.loading = false
        })
      }).catch(e => {
        if (Object.keys(e.response.data.error).some(e => e === 'email')) {
          this.errorMessage = this.$t('email_exists')
        }
        if (Object.keys(e.response.data.error).some(e => e === 'club_id')) {
          this.errorMessage = this.$t('club_id_taken')
        }
        this.loading = false
      })
    }
  }
}
</script>
